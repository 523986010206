import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";
import Header from "@components/shared/header";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "@redux/shared/actions";
import { RootState } from "@redux/reducers";
import dynamic from "next/dynamic";
import { useDomainContext } from "@hooks/useDomainContext";

import GoAutoWarrantyLogo from "@components/goautowarranty/logo";
import Mobile from "@components/shared/blogPost/sideWidget/mobile";

const Footer = dynamic(() => import("@components/shared/footer"));
const Modal = dynamic(() => import("@components/shared/modal"));

const CloseIcon = dynamic(() => import("@components/svg/closeIcon"));

const TermsAndConditions = dynamic(
    () => import("@components/shared/legal/termsAndConditions"),
);

const PrivacyPolicy = dynamic(
    () => import("@components/shared/legal/privacyPolicy"),
);

export default function GoAutoWarrantyWrapper({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const dispatch = useDispatch();
    const {
        visitDetails: { region },
    } = useDomainContext();
    const handleClose = () => {
        dispatch(setModal({ active: false, content: undefined }));
    };

    const { active: modalActive, content: modalContent } = useSelector(
        (state: RootState) => state.rootReducer.shared.modal,
    );

    const getModalContent = () => {
        switch (modalContent) {
            case "privacy":
                return (
                    <PrivacyPolicy
                        category="GoAutoWarranty"
                        domain="GoAutoWarranty.com"
                    />
                );
            case "terms":
                return (
                    <TermsAndConditions
                        category="GoAutoWarranty"
                        domain="GoAutoWarranty.com"
                    />
                );
            case "sideWidget":
                return <Mobile />;
            default:
                return null;
        }
    };

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />

                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
                    rel="stylesheet"
                />
            </Head>

            <div style={{ fontFamily: `'Inter', sans-serif` }}>
                <Header
                    boxShadow="1px 1px 6px 3px #ededed"
                    slogan={`TOP AUTO WARRANTY COMPANIES in ${
                        region ? region : ""
                    }`}
                >
                    <GoAutoWarrantyLogo />
                </Header>
                <main className="max-w-7xl mx-auto block px-4vw lg:px-4">
                    {children}
                </main>
                <Footer />
                <Modal
                    onClose={handleClose}
                    active={modalActive}
                    closeIcon={<CloseIcon />}
                    backdropClosable
                    modalStyles={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight:
                            modalContent === "sideWidget" ? "80vh" : "100%",
                    }}
                    modalClassName="h-[90vh] rounded"
                    bodyStyles={{ overflowY: "scroll" }}
                    width={900}
                    header={
                        modalContent === "privacy"
                            ? "Privacy Policy"
                            : modalContent === "terms"
                            ? "Terms and Conditions"
                            : modalContent === "sideWidget"
                            ? "Contents"
                            : undefined
                    }
                >
                    {getModalContent()}
                </Modal>
            </div>
        </>
    );
}
